/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import autobind from 'autobind-decorator';
import imagesLoaded from 'imagesloaded';

import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImage';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {GridType, LoadMoreType} from '../../../types/galleryTypes';
import {withGlobals} from '../../../globalPropsContext';
import {DEFAULT_MOBILE_PRODUCTS_COUNT} from '../../../constants';
import {ProductListGrid} from './ProductListGrid';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {Pagination, PaginationProps} from './Pagination';
import {Loader} from '../../../common/components/Loader/Loader';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
}

@withGlobals
@withTranslations()
@autobind
export class ProductList extends React.Component<ProductListProps, ProductListState> {
  private readonly listRef: React.RefObject<HTMLDivElement>;
  private imagesLoaded = false;
  private isInteractive = false;
  private focusedAt: number = -1;
  private scrollAfterUpdate = false;
  private loadMoreCalled = false;
  private readonly SCROLL_EVENT_NAME = 'scroll';

  public constructor(props: ProductListProps) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      inBrowser: false,
    };
  }

  public componentDidMount(): void {
    const {loadMoreType} = this.props.globals;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.addEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }

    this.setState({inBrowser: true}, () => {
      imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    if (this.scrollAfterUpdate) {
      this.scrollToTop();
      this.scrollAfterUpdate = false;
    }

    this.focusedAt = prevProps.globals.focusedProductIndex;
    this.loadMoreCalled = false;
  }

  public componentWillUnmount() {
    const {loadMoreType} = this.props.globals;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }
  }

  public render() {
    const {products} = this.props.globals;
    return (
      <section data-hook="product-list" aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get isMobileOverride() {
    return this.props.globals.isMobile;
  }

  private get maxProductsPerPage(): number {
    const {isAutoGrid} = this.props.globals;
    const {gallery_productsCount, galleryColumns, galleryRows} = this.props.globals.styleParams.numbers;
    switch (true) {
      case isAutoGrid:
        return gallery_productsCount;
      case this.isMobileOverride:
        return DEFAULT_MOBILE_PRODUCTS_COUNT;
      default:
        return galleryColumns * galleryRows;
    }
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private getFocusableItemIndex() {
    const {isFirstPage, focusedProductIndex} = this.props.globals;
    const prevFocusedProductIndex = this.focusedAt;

    if (!isFirstPage && focusedProductIndex !== prevFocusedProductIndex) {
      return this.props.globals.focusedProductIndex;
    }

    return -1;
  }

  private getNumberOfVisibleProducts(): number {
    if (this.gridType() === GridType.AUTO && this.props.globals.isFirstPage) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }

    if (this.props.globals.isFirstPage) {
      return this.maxProductsPerPage;
    }

    return this.props.globals.products.length;
  }

  private gridType() {
    return this.props.globals.isAutoGrid ? GridType.AUTO : GridType.MANUAL;
  }

  private getProductList() {
    const {products, styleParams} = this.props.globals;
    const nextProducts = products.slice(0, this.getNumberOfVisibleProducts());

    return (
      <div ref={this.listRef}>
        <ProductListGrid
          products={nextProducts}
          isMobileOverride={this.isMobileOverride}
          gridType={this.gridType()}
          renderKey={String(styleParams.numbers.gallery_productSize)}
          focusAt={this.getFocusableItemIndex()}
          ProductItem={ProductItemWithGlobals}
        />
        {this.renderLoadMore()}
      </div>
    );
  }

  private async onScroll() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    if (this.loadMoreCalled || productsRequestInProgress || !this.shouldAllowLoadMore()) {
      return;
    }

    const productListElementRect = document.querySelector('[data-hook="product-list"]').getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    const productListElementTop = productListElementRect.top - bodyRect.top;
    const maxScroll = productListElementRect.height + productListElementTop;
    const currScrollBottom = document.documentElement.scrollTop + window.innerHeight;
    const bottomElementScrollRatio = currScrollBottom / maxScroll;

    if (bottomElementScrollRatio >= 1) {
      this.loadMoreCalled = true;
      await this.loadMore();
    }
  }

  private renderLoadMore() {
    const {loadMoreType} = this.props.globals;

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.renderPagination();
      case LoadMoreType.INFINITE:
        return this.renderInfiniteScrollLoader();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldAllowLoadMore() && this.getLoadMoreButton();
    }
  }

  private shouldAllowLoadMore(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton loadMoreClicked={this.loadMore} />;
  }

  private async loadMore() {
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts(), this.maxProductsPerPage);
  }

  private scrollToTop() {
    if (window.Wix) {
      const {compId} = this.props.globals;
      window.Wix.navigateToComponent(compId);
    } else {
      const top = this.listRef.current.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({left: 0, top, behavior: 'smooth'});
    }
  }

  private renderPagination() {
    const {
      currentPage,
      totalProducts,
      handlePagination,
      styleParams: {
        booleans: {gallery_paginationFirstLastArrows},
      },
      paginationMode,
    } = this.props.globals;
    const totalPages = Math.ceil(totalProducts / this.maxProductsPerPage);
    const showFirstLastNavButtons = gallery_paginationFirstLastArrows && totalProducts <= 10_000;

    const props: PaginationProps = {
      currentPage,
      paginationMode,
      totalPages,
      showFirstLastNavButtons,
      handlePagination: (page) => {
        this.scrollAfterUpdate = true;
        handlePagination(page);
      },
    };

    return <Pagination {...props} />;
  }

  private renderInfiniteScrollLoader() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    return productsRequestInProgress && <Loader />;
  }
}
