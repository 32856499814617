import React, {Component} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import {Dropdown, DropdownOptionProps, DropdownProps} from 'wix-ui-tpa/Dropdown';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductVariantCalculator';
import s from './ProductOptions.scss';

export interface IProductOptionsProps {
  product: IProduct;
  selectionIds: number[];
  onSelectionIdsChange(optionSelections: IProductOptionsProps['selectionIds']): void;
  variantsAvailability: IProductSelectionAvailabilityMap;
}

export enum DataHook {
  ProductOptions = 'product-options',
  DropdownOptionWrap = 'product-options-dropdown-wrap',
  DropdownOption = 'product-options-dropdown',
}

export class ProductOptions extends Component<IProductOptionsProps> {
  private readonly getAllVisibleDropdownOptions = () => {
    /**
     * TODO: This should be reimplemented to take in account
     * number of color options and sort them
     * with same logic on product page
     */
    return this.props.product.options.filter((option) => option.optionType === 'DROP_DOWN').slice(0, 2);
  };

  private readonly sealChangeHandler = (optionsSelections: number[]): DropdownProps['onChange'] => (selected) => {
    const {
      props: {selectionIds, onSelectionIdsChange},
    } = this;

    const nextSelectionIds = [
      ...selectionIds.filter((selectionId) => !optionsSelections.includes(selectionId)),
      parseInt(selected.id, 10),
    ];

    onSelectionIdsChange(nextSelectionIds);
  };

  private readonly renderDropdownOption = ({
    option: {id, title, selections},
    availability,
  }: {
    option: IProduct['options'][number];
    availability: IProductSelectionAvailabilityMap[string];
  }) => {
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));

    return (
      <div data-hook={DataHook.DropdownOptionWrap} key={id}>
        <Dropdown
          data-hook={DataHook.DropdownOption}
          placeholder={title}
          options={options}
          onChange={this.sealChangeHandler(selections.map((selection) => selection.id))}
        />
      </div>
    );
  };

  private readonly renderAllDropdownOptions = () => {
    const dropDownOptions = this.getAllVisibleDropdownOptions();

    if (!dropDownOptions.length) {
      return null;
    }

    const {
      props: {variantsAvailability},
    } = this;

    return (
      <div>
        {dropDownOptions.map((option) => (
          <div key={option.id} className={s.dropdown}>
            {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
          </div>
        ))}
      </div>
    );
  };

  public render() {
    return this.renderAllDropdownOptions();
  }
}
