import * as React from 'react';
import paginationStyle from './pagination.st.css';
import s from './ProductList.scss';
import {Pagination as PaginationUI} from 'wix-ui-tpa/Pagination';
import {PaginationTypeName} from '../../../types/galleryTypes';

export interface PaginationProps {
  handlePagination(page: number): void;
  currentPage: number;
  totalPages: number;
  paginationMode: PaginationTypeName;
  showFirstLastNavButtons: boolean;
}

export const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const {currentPage, paginationMode, showFirstLastNavButtons, totalPages, handlePagination} = props;

  return (
    <div data-hook="product-list-pagination" className={s.pagination}>
      <PaginationUI
        {...paginationStyle('root')}
        currentPage={currentPage}
        data-hook={`product-list-tpa-pagination`}
        onChange={({page}) => handlePagination(page)}
        paginationMode={paginationMode}
        showFirstLastNavButtons={showFirstLastNavButtons}
        showFirstPage
        showLastPage
        totalPages={totalPages}
      />
    </div>
  );
};
